import styled from "styled-components"
import {useEffect, useState, useRef} from "react";
import { AperitifGrid, BeveragesGrid, MainGrid, SweetsGrid } from "../components/MenuGrids/baronMenu";
import { CopyRightStyled } from "../components/MenuGrids/baronMenu/generalStyles";

function BaronMenu({}) {

    const [triggerLanguage, setTriggerLanguage] = useState('en')
    const sliderContainer = useRef(null)
    const baronSectionsRef = useRef([]);
    const baronCatRef = useRef([]);
    let categoriesIndices = ['0', '1', '2', '3']


    //triggers the arabic/english menu
    function handleTriggerMenu(language) {
        setTriggerLanguage(language);
    }

    //activate the cateogry we click on
    function navClickHandler(id) {
        activeCategoryHandler(id)
    }

    //activates/deactivates the categories that we click on or we are currently on
    function activeCategoryHandler(sectionId) {
        var index = sectionId-1;

        baronCatRef.current[index].style.color = "#007481"

        categoriesIndices.map((item) => {
            if(item != index) {
                baronCatRef.current[item].style.color = "#3D3935"
            }
        })
    }

    //this function tracks the user position as he scrolls down the page
    function windowScrollTracker(currentScroll) {

        var sectionFromTop;
        var sectionId;

        //gets the id of the cateogry we are on as we scroll
        baronSectionsRef.current.forEach((section) => {
            sectionFromTop = section.getBoundingClientRect().top;
          
            if (sectionFromTop <= 10) {
                sectionId = section.getAttribute("id");
            }
        }) 

        console.log("the current section id is: ", sectionId)

        navBarTracker(sectionId)
    }

    async function navBarTracker(sectionId) {

        switch(sectionId) {
            //activates the to share cateogry
            case '1':
                await activeCategoryHandler(sectionId)
            break;

            //activates the pasta and dum cateogory
            case '2':
                await activeCategoryHandler(sectionId)
            break;

            //activates the sweets category
            case '3':
                await activeCategoryHandler(sectionId)
            break;

            //activates the beverages category
            case '4':
                await activeCategoryHandler(sectionId)
            break;
        }

        return "resolved";
    }


    //function to move the slider
    function useHorizontalScrollEventEn(callback) {
        const positionRef = useRef(0);

        return (e) => {
            const x = e.currentTarget.scrollLeft;
            if(x !== positionRef.current) {
                positionRef.current = x;
                callback(e);
            }
        }
    }

    useEffect(() => {
        function handleScroll(e) {
            var currentScroll = window.pageYOffset;
            windowScrollTracker(currentScroll);
        }

        var timer = null;

        window.addEventListener('scroll', function() {
            if(timer !== null) {
                clearTimeout(timer)
            }
            timer = setTimeout(function () {
                handleScroll()
            }, 50)
        })

    }, [])

    if(triggerLanguage == "en") {
        return (
            <BaronPageStyled>
                <SliderContainerStyled ref = {sliderContainer} onScroll={useHorizontalScrollEventEn} triggerLanguage = "en">
                    <NavTextStyled>
                        <a href = "#1" ref = {(el) => baronCatRef.current[0] = el} onClick = {() => navClickHandler("1")}>
                            <span>main</span>
                        </a>    
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#2" ref = {(el) => baronCatRef.current[1] = el} onClick = {() => navClickHandler("2")}>
                            <span>aperitif</span>
                        </a>    
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#3" ref = {(el) => baronCatRef.current[2] = el} onClick = {() => navClickHandler("3")}>
                            <span>sweets</span>
                        </a>    
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#4" ref = {(el) => baronCatRef.current[3] = el} onClick = {() => navClickHandler("4")}>
                            <span>beverages</span>
                        </a>    
                    </NavTextStyled>
                   
                </SliderContainerStyled>
                <LanguageTriggerContainerStyled triggerLanguage = "en">
                    <span onClick = {() => handleTriggerMenu("ar")}>عربي</span>
                </LanguageTriggerContainerStyled>
                <CategoryContainerStyled>
                    <section id = "1" ref = {(el) => baronSectionsRef.current[0] = el}>
                        <MainGrid triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "2" ref = {(el) => baronSectionsRef.current[1] = el}>
                        <AperitifGrid triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "3" ref = {(el) => baronSectionsRef.current[2] = el}>
                        <SweetsGrid triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "4" ref = {(el) => baronSectionsRef.current[3] = el}>
                        <BeveragesGrid triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CopyRightStyled>
                    <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                                <img src = "/Developed-By-LSD.svg"/>
                    </a>
                </CopyRightStyled>
            </BaronPageStyled>
        )
    }

    if(triggerLanguage == "ar") {
        return (
            <BaronPageStyled>
                <SliderContainerStyled ref = {sliderContainer} onScroll={useHorizontalScrollEventEn} triggerLanguage = "ar">
                    <NavTextStyled>
                        <a href = "#4" ref = {(el) => baronCatRef.current[3] = el} onClick = {() => navClickHandler("4")}>
                            <span>مشروبات</span>
                        </a>    
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#3" ref = {(el) => baronCatRef.current[2] = el} onClick = {() => navClickHandler("3")}>
                            <span>حلويات</span>
                        </a>    
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#2" ref = {(el) => baronCatRef.current[1] = el} onClick = {() => navClickHandler("2")}>
                            <span>موكتيل</span>
                        </a>    
                    </NavTextStyled>
                    <NavTextStyled>
                        <a href = "#1" ref = {(el) => baronCatRef.current[0] = el} onClick = {() => navClickHandler("1")}>
                            <span>main</span>
                        </a>    
                    </NavTextStyled>
                   
                </SliderContainerStyled>
                <LanguageTriggerContainerStyled triggerLanguage = "ar">
                    <span onClick = {() => handleTriggerMenu("en")}>En</span>
                </LanguageTriggerContainerStyled>
                <CategoryContainerStyled>
                    <section id = "1" ref = {(el) => baronSectionsRef.current[0] = el}>
                        <MainGrid triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "2" ref = {(el) => baronSectionsRef.current[1] = el}>
                        <AperitifGrid triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "3" ref = {(el) => baronSectionsRef.current[2] = el}>
                        <SweetsGrid triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <section id = "4" ref = {(el) => baronSectionsRef.current[3] = el}>
                        <BeveragesGrid triggerLanguage = {triggerLanguage}/>
                    </section>
                </CategoryContainerStyled>
                <CopyRightStyled>
                    <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                                <img src = "/Developed-By-LSD.svg"/>
                    </a>
                </CopyRightStyled>
            </BaronPageStyled>
        )
    }
}

const BaronPageStyled = styled.div`
    position: relative;
    width: 100vw;
`

const SliderContainerStyled = styled.div`
    position: fixed;
    inset: 0;
    left: ${props => props.triggerLanguage == "en" ? "0%" : "20%"};
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    gap: 20px;
    align-items: center;
    width: 80%;
    height: 50px;
    z-index: 1;
    overflow: scroll;
    scroll-behavior: smooth;
`

const NavTextStyled = styled.div`
    position: relative;
    text-transform: uppercase;
    font-family:  "Roboto Medium";
    font-size: 12px;
    

    a {
        color: #3D3935;
    }

`

const LanguageTriggerContainerStyled = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    background-color: white;
    align-items: center;
    z-index: 1;
    inset: 0px;
    left: ${props => props.triggerLanguage == "en" ? "80%" : "0%"};
    width: 20%;
    height: 50px;
    font-family: ${props => props.triggerLanguage == "en" ? "Tajawal Medium" : "Roboto Medium"};
    font-size: 12px;

     span {
        padding: 5px;
     }
`
const CategoryContainerStyled = styled.div`
    position: relative;
    margin-top: 80px;
`


export default BaronMenu;