const hot_drinks = [
    {
        name: "Americano",
        price: "22",
        image_path: "/habra-menu-designs/hot drinks/Habra Menu- Americano.jpg",
    },

    {
        name: "Café latte",
        price: "24",
        image_path: "/habra-menu-designs/hot drinks/Habra Menu- Latte.jpg",
    },

    {
        name: "Cappuccino",
        price: "26",
        image_path: "/habra-menu-designs/hot drinks/Habra Menu- Cappuccino.jpg",
    },

    {
        name: "Double espresso",
        price: "22",
        image_path: "/habra-menu-designs/hot drinks/Habra Menu- Double Espresso.jpg",
    },

    {
        name: "Espresso",
        price: "18",
        image_path: "/habra-menu-designs/hot drinks/Habra Menu- Single Espresso.jpg",
    },
]

export default hot_drinks;