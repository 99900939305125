const specialityCuts = [

    {
        name: "Angus rib eye",
        price: "215",
        image_path: "/habra-menu-designs/speciality cuts/Habra Menu- Wagyu Ribeye.jpg",
    },

    {
        name: "Angus tenderloin",
        price: "160",
        image_path: "/habra-menu-designs/speciality cuts/Habra Menu- Angus Tenderloin-2.jpg",
    },

    {
        name: "Wagyu rib eye",
        price: "310",
        image_path: "/habra-menu-designs/speciality cuts/Habra Menu- Wagyu Ribeye.jpg",
    },

    {
        name: "Wagyu tenderloin",
        price: "195",
        image_path: "/habra-menu-designs/speciality cuts/Habra Menu- Wagyu Tenderloin.jpg",
    },
]

export default specialityCuts;