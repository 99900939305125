const veggie_plates = [
    {
        name: "Baby gem salad",
        price: "58",
        image_path: "/habra-menu-designs/veggie/Habra Menu- Baby Gem Salad.jpg",
    },

    {
        name: "Baked beetroot",
        price: "35",
        image_path: "/habra-menu-designs/veggie/Habra Menu- Baked Beetroot.jpg",
    },

    {
        name: "Crispy okra",
        price: "35",
        image_path: "/habra-menu-designs/veggie/Habra Menu- Crispy Okra.jpg",
    },

    {
        name: "Grilled avocado",
        price: "45",
        image_path: "/habra-menu-designs/veggie/Habra Menu- Grilled Avocado.jpg",
    },

    {
        name: "Kale and nori slaw",
        price: "32",
        image_path: "/habra-menu-designs/veggie/Habra Menu- Kale & Nori.jpg",
    },

    {
        name: "Roasted baby carrots",
        price: "52",
        image_path: "/habra-menu-designs/veggie/Habra Menu- Roasted Baby Carrots.jpg",
    },

    {
        name: "Baked sweet potato",
        price: "39",
        image_path: "/habra-menu-designs/veggie/Habra Menu- Baked Sweet Potato.jpg",
    },
]

export default veggie_plates;