import { Grid } from "@mui/material"
import { useState } from "react";
import styled from "styled-components";
import { ltb_soups } from "../../../data/ltb-categories-details";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";

function SoupsGrid() {

    const [showDish, setShowDish] = useState(-1);
    function handleClick(id) {
        setShowDish(id);
    }

    if(showDish < 0) {
        return (
            <Grid container spacing = {1} rowSpacing = {0} paddingTop = {3} paddingBottom = {3} width = {"95%"}>
                <Grid item xs = {6}>
                    <div  onClick = {() => handleClick("0")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Soups JPG/Grid JPG/Chicken soup.jpg"/>
                    <div className="dish-title">Chicken Soup</div>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div onClick = {() => handleClick("1")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Soups JPG/Grid JPG/French onion.jpg"/>
                    <div className="dish-title">French Soup</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "3" onClick = {() => handleClick("2")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Soups JPG/Grid JPG/Tomato soup.jpg"/>
                    <div className="dish-title">Tomato Soup</div>
                    </div>
                </Grid>
            </Grid>
        )
    }

    if(showDish >= 0) {
        return (
            <Test id = "blt-testing">
                <div className = "dish-heading">
                    Soup
                </div>
                <div className = "dish-image-container">
                    <img src = {`/${ltb_soups[showDish]}`}/>
                </div>  
                <div className = "dish-close-icon" onClick ={()=>setShowDish(-1)}>
                    <FontAwesomeIcon icon = {faXmark}/>
                </div>
            </Test>
        )
    }
}

const Test = styled.div`
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    inset:0;
    background-color: #011e41;
    z-index:1;

    img {
    }

    @media only screen and (min-width: 500px) {
        img {
            /* max-width: 500px;
            left:20%; */
            top: -10%;
        }
    }

    
`
export default SoupsGrid;