const sharing_plates = [
    {
        name: "Bone marrow",
        price: "55",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Bone Marrow.jpg",
    },

    {
        name: "Korean fries",
        price: "58",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Korean fries.jpg",
    },

    {
        name: "Short rib tacos",
        price: "85",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- short Ribs Tacos.jpg",
    },

    {
        name: "Crispy chicken tacos",
        price: "55",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Cripsy Chicken Tacos.jpg",
    },

    {
        name: "Kaffir coconut chicken skewers",
        price: "75",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- kaffir Coconut Chicken Skewers.jpg",
    },

    {
        name: "Tendys sliders",
        price: "98",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Tendy's Slider.jpg",
    },

    {
        name: "Wagyu swiss slider",
        price: "145",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Wagyu Swiss Slider.jpg",
    },

    {
        name: "Sliced rib eye",
        price: "190",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Sliced Ribeye.jpg",
    },

    {
        name: "Signature lamb chops",
        price: "85",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Signature Lamb Chops.jpg",
    },

    {
        name: "Chicken slider 13 (grilled)",
        price: "55",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Chicken Slider 13 Grilled.jpg",
    },

    {
        name: "Chicken slider 13 (fried)",
        price: "55",
        image_path: "/habra-menu-designs/sharing plates/Habra Menu- Chicken Slider 13 Fried.jpg",
    },
]


export default sharing_plates;