const habra_plates = [
    {
        name: "Korean short rib",
        price: "145",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Korean Short Ribs.jpg",
    },

    {
        name: "Beef back ribs",
        price: "145",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Beef Back Ribs.jpg",
    },

    {
        name: "Burger bong",
        price: "85",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Burger Bong.jpg",
    },

    {
        name: "Chefs's burger",
        price: "135",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Chef Burger.jpg",
    },

    {
        name: "Fried chicken burger, house curry mayo",
        price: "75",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Fried Chicken Burger.jpg",
    },

    {
        name: "Deconstructed gyros",
        price: "210",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Gyros.jpg",
    },

    {
        name: "Hoisin pulled ribs",
        price: "120",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Hoisin Pulled Ribs.jpg",
    },

    {
        name: "Sliced beef fillet, jus, spinach pottage",
        price: "190",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Sliced Beef Fillet Jus Spinach Pottage.jpg",
    },

    {
        name: `"Snack" plate`,
        price: "98",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Snack Plate.jpg",
    },

    {
        name: "Spinach & ricotta stuffed chicken",
        price: "85",
        image_path: "/habra-menu-designs/habra plates/Habra Menu- Chicken Spinach Ricotta.jpg",
    },
]

export default habra_plates;