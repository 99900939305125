import styled from "styled-components"

const MainCategoryContainerStyled = styled.div`
    position: relative;
    padding: 10px 10px;
`

const CategoryContainerStyled = styled.div`
    position: relative;
    margin-bottom: 30px;
    padding: 6px 6px;
`

const CategoryHeaderStyled = styled.div`
    position: relative;
    margin-bottom: 15px;
    font-family:  "Roboto Light";
    font-size: 20px;
    margin-top: 40px;
    text-transform: uppercase;
    text-align: left;
`

const CategoryHeaderArStyled = styled(CategoryHeaderStyled)`
    text-align: right;

    //add the arabic font here later
`

const CategorySubHeaderStyled = styled.div`
    position: relative;
    margin-bottom: 15px;
    font-family:  "Roboto Light";
    font-size: 15px;
    text-transform: uppercase;
    text-align: left;
`

const CategorySubHeaderArStyled = styled(CategorySubHeaderStyled)`
    text-align: right;

    //add the font here later
`

const CategoryGridStyled = styled.div`
    position: relative
`

const ItemContainerStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 3px;
`

const ItemImageContainer = styled.div`
    position: relative;
    width: 100%;

    img {
        width: 100%;
    }
`

const ItemBoxTwoStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 11px;

`
const ItemDetailsContainer = styled.div`
    position: relative;
    width: 66%;
`

const ItemPriceContainer = styled.div`
    position: relative;
    font-family: "Roboto Light";
    padding-left: 12px;

    span {
        font-size: 8px;
    }
`

const ItemPriceContainerAr = styled(ItemPriceContainer)`
    padding-left: 0px;
    display: flex;
    gap: 4px;
    flex-direction: row-reverse;
    justify-content: flex-start;
`

const ItemNameStyled = styled.span`
    font-family: "Roboto Medium";
    text-transform: uppercase;
    text-align: left;
`

const ItemNameArStyled = styled(ItemNameStyled)`
    font-family: "Tajawal Medium";
`

const ItemDetailsStyled = styled.span`
    font-family:  "Roboto Light";
`

const ItemDetailsArStyled = styled(ItemDetailsStyled)`
    font-family: "Tajawal Light";
`

const CopyRightStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    padding: 40px 0px;
    width: 100%;
    img {
        width: 45%;
    }
`

export {
    MainCategoryContainerStyled,
    CategoryContainerStyled,
    CategoryHeaderStyled,
    CategoryHeaderArStyled,
    CategorySubHeaderStyled,
    CategorySubHeaderArStyled,
    CategoryGridStyled,
    ItemContainerStyled,
    ItemImageContainer,
    ItemBoxTwoStyled,
    ItemDetailsContainer,
    ItemDetailsStyled,
    ItemDetailsArStyled,
    ItemPriceContainer,
    ItemPriceContainerAr,
    ItemNameStyled,
    ItemNameArStyled,
    CopyRightStyled,
}