const drinks = [

    {
        name: "Coke",
        price: "18",
        image_path: "/habra-menu-designs/drinks/Habra Menu- Coca Cola Regular.jpg",
    },

    {
        name: "Diet coke",
        price: "18",
        image_path: "/habra-menu-designs/drinks/Habra Menu- Coca Cola Light.jpg",
    },

    {
        name: "Sprite",
        price: "18",
        image_path: "/habra-menu-designs/drinks/Habra Menu- Sprite.jpg",
    },

    {
        name: "Aqua panna 250 ml",
        price: "20",
        image_path: "/habra-menu-designs/drinks/Habra Menu- Mineral Water.jpg",
    },

    {
        name: "San pallegrino 250 ml",
        price: "20",
        image_path: "/habra-menu-designs/drinks/Habra Menu- Sparkling Water.jpg",
    },
]

export default drinks;