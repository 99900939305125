const sides = [
    {
        name: "Brioche garlic bread",
        price: "25",
        image_path: "/habra-menu-designs/sides/Habra Menu- Brioche Garlic Bread.jpg",
    },

    {
        name: "Mashed potato",
        price: "34",
        image_path: "/habra-menu-designs/sides/Habra Menu- Mashed Potato.jpg",
    },
]

export default sides;