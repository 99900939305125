import {Grid} from "@mui/material";
import { MainCategoryContainerStyled, CategoryContainerStyled, CategoryHeaderStyled, CategoryHeaderArStyled,  CategorySubHeaderStyled, CategorySubHeaderArStyled, CategoryGridStyled, ItemContainerStyled, ItemImageContainer, ItemBoxTwoStyled, ItemDetailsContainer,  ItemPriceContainer, ItemPriceContainerAr, ItemNameStyled, ItemNameArStyled } from "./generalStyles";
import {hotDrinks} from "../../../data/habraCategories"

function HotDrinks({triggerLanguage}) {
    if(triggerLanguage == "en") {
        return (
            <MainCategoryContainerStyled>
                    <CategoryContainerStyled>
                        <span>HOT DRINKS</span>
                    </CategoryContainerStyled>
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {4}>
                            {hotDrinks.map(({name, price, image_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {image_path}/>
                                            </ItemImageContainer>
                                                <ItemBoxTwoStyled>
                                                    <ItemDetailsContainer>
                                                        <div>
                                                            <ItemNameStyled>
                                                                    {name}
                                                            </ItemNameStyled>
                                                        </div>
                                                    </ItemDetailsContainer>
                                                    <ItemPriceContainer>
                                                        {price} <span>QR</span>
                                                </ItemPriceContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </CategoryGridStyled>
                </MainCategoryContainerStyled>
        )   
    }
}

export default HotDrinks;