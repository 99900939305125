import styled from "styled-components";
import {Grid} from "@mui/material"
import { sweetsOne, sweetsOneAr } from "../../../data/baronCategories/baronSweets";
import { MainCategoryContainerStyled, CategoryContainerStyled, CategoryHeaderStyled, CategoryHeaderArStyled,  CategorySubHeaderStyled, CategorySubHeaderArStyled, CategoryGridStyled, ItemContainerStyled, ItemImageContainer, ItemBoxTwoStyled, ItemDetailsContainer, ItemDetailsStyled, ItemDetailsArStyled,  ItemPriceContainer, ItemPriceContainerAr, ItemNameStyled, ItemNameArStyled } from "./generalStyles";

function SweetsGrid({triggerLanguage}) {

    if(triggerLanguage == "en") {
        return (
            <MainCategoryContainerStyled>
                <CategoryContainerStyled>
                    <CategoryHeaderStyled>
                       sweets
                    </CategoryHeaderStyled>
                    {/* <CategorySubHeaderStyled>
                        Sub Header one
                    </CategorySubHeaderStyled> */}
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {4}>
                        {
                            sweetsOne.map(({name, details, price, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemDetailsContainer>
                                                    <div>
                                                        <ItemNameStyled>
                                                            {name}
                                                        </ItemNameStyled>
                                                        <ItemDetailsStyled>{details}</ItemDetailsStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                                <ItemPriceContainer>
                                                    {price} <span>QR</span>
                                                </ItemPriceContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                            <Grid item xs = {6} sm = {6}>
                                <ItemContainerStyled>
                                    <ItemImageContainer>
                                        <img src = "/baron-menu-designs/Sweets Pics/gray color.jpg" />
                                    </ItemImageContainer>
                                    <ItemBoxTwoStyled>
                                        <ItemDetailsContainer>
                                            <div>
                                                <ItemNameStyled>
                                                    A “MESS” , 
                                                </ItemNameStyled>
                                                <ItemDetailsStyled> YOGURT CREAM, DUQQAH, BERRIES, ICE CREAM, LEMON</ItemDetailsStyled>
                                            </div>
                                        </ItemDetailsContainer>
                                        <ItemPriceContainer>
                                                85 <span>QR</span>
                                        </ItemPriceContainer>
                                    </ItemBoxTwoStyled>
                                </ItemContainerStyled>
                            </Grid>
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
            </MainCategoryContainerStyled>
        )
    }

    if(triggerLanguage == "ar") {
        return (
            <MainCategoryContainerStyled>
                <CategoryContainerStyled>
                    <CategoryHeaderArStyled>
                    حلويات
                    </CategoryHeaderArStyled>
                    {/* <CategorySubHeaderStyled>
                        Sub Header one
                    </CategorySubHeaderStyled> */}
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {4}>
                        {
                            sweetsOneAr.map(({name, details, price, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemPriceContainerAr>
                                                    <div> 40 </div>
                                                    <div><span>ر.ق</span></div>   
                                                </ItemPriceContainerAr>
                                                <ItemDetailsContainer style = {{textAlign: "right"}}>
                                                    <div>
                                                        <ItemNameArStyled>
                                                            {name}
                                                        </ItemNameArStyled>
                                                        <ItemDetailsArStyled>{details}</ItemDetailsArStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                            <Grid item xs = {6} sm = {6}>
                                <ItemContainerStyled>
                                    <ItemImageContainer>
                                        <img src = "/baron-menu-designs/Sweets Pics/gray color.jpg" />
                                    </ItemImageContainer>
                                    <ItemBoxTwoStyled>
                                        <ItemPriceContainerAr>
                                            <div> 85 </div>
                                            <div><span>ر.ق</span></div>   
                                        </ItemPriceContainerAr>
                                        <ItemDetailsContainer style = {{textAlign: "right"}}>
                                            <div>
                                                <ItemNameArStyled>
                                                "فوضى،"
                                                </ItemNameArStyled>
                                                <ItemDetailsArStyled>كريمة الزبادي ، الدقة ، التوت ، الآيس كريم ، الليمون </ItemDetailsArStyled>
                                            </div>
                                        </ItemDetailsContainer>
                                    </ItemBoxTwoStyled>
                                </ItemContainerStyled>
                            </Grid>
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
            </MainCategoryContainerStyled>
        )
    }
}

export default SweetsGrid;
