import { Grid } from "@mui/material"
import { useState } from "react";
import styled from "styled-components";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ltb_hot_main_course } from "../../../data/ltb-categories-details";

function MainCourseGrid() {

    const [showDish, setShowDish] = useState(-1);
    function handleClick(id) {
        setShowDish(id);
    }

    if(showDish < 0) {
        return (
            <Grid container spacing = {1} rowSpacing = {0} paddingTop = {3} paddingBottom = {3} width = {"95%"}>
                <Grid item xs = {6}>
                    <div  onClick = {() => handleClick("0")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Main Course JPG/Grid JPG/Baby chicken main course.jpg"/>
                    <div className="dish-title">Baby Chicken W/ Candied Lemon</div>
                    </div>
                </Grid>
                <Grid item xs = {6}>
                    <div onClick = {() => handleClick("1")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Main Course JPG/Grid JPG/Black angus main course.jpg"/>
                    <div className="dish-title">Black Angus Tenderloin</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "2" onClick = {() => handleClick("2")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Main Course JPG/Grid JPG/Duck leg main course.jpg"/>
                    <div className="dish-title">Duck Leg Confit</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "3" onClick = {() => handleClick("3")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Main Course JPG/Grid JPG/LTB burger main course.jpg"/>
                    <div className="dish-title">LTB Mac Burger</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "4" onClick = {() => handleClick("4")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Main Course JPG/Grid JPG/Panang main course.jpg"/>
                    <div className="dish-title">Panang Chicken / Shrimp</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "5" onClick = {() => handleClick("5")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Main Course JPG/Grid JPG/Seabass fillet main course.jpg"/>
                    <div className="dish-title">SeaBass Fillet</div>
                    </div>
                </Grid>
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "6" onClick = {() => handleClick("6")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Main Course JPG/Grid JPG/short ribs main course.jpg"/>
                    <div className="dish-title">Short Ribs</div>
                    </div>
                </Grid>
                {/* <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "7" onClick = {() => handleClick("7")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Main Course JPG/Grid JPG/Vegan garden steak main course.jpg"/>
                    <div className="dish-title">Vegan Garden Steak</div>
                    </div>
                </Grid> */}
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "8" onClick = {() => handleClick("8")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Main Course JPG/Grid JPG/Wagy tenderloin main course.jpg"/>
                    <div className="dish-title">Wagyu Tenderloin</div>
                    </div>
                </Grid>
                {/* <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "9" onClick = {() => handleClick("9")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Main Course JPG/Grid JPG/Whole chicken main course.jpg"/>
                    <div className="dish-title">Whole Chicken Stuffed</div>
                    </div>
                </Grid> */}
                <Grid item xs = {6} style = {{paddingTop: "2px"}}>
                    <div id = "10" onClick = {() => handleClick("10")}>
                    <img style = {{width: "100%"}} src = "/blt-menu-designs/Main Course JPG/Grid JPG/Miso Salmon main course.jpg"/>
                    <div className="dish-title">Miso Salmon</div>
                    </div>
                </Grid>
            </Grid>
        )
    }

    if(showDish >= 0) {
        return (
            <Test>
            <div className = "dish-heading">
                Main Course
            </div>
            <div className = "dish-image-container">
                <img src = {`/${ltb_hot_main_course[showDish]}`}/>
            </div>  
            <div className = "dish-close-icon" onClick ={()=>setShowDish(-1)}>
                <FontAwesomeIcon icon = {faXmark}/>
            </div>
        </Test>
        )
    }
}

const Test = styled.div`
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
    inset:0;
    background-color: #011e41;
    z-index:1;

    img {
    }

    @media only screen and (min-width: 500px) {
        img {
            /* max-width: 500px;
            left:20%; */
            top: -10%;
        }
    }

    
`
export default MainCourseGrid;